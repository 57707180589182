import React, { useEffect } from "react"
import "../../../assets/styles/_index.scss"
import Favicon from "react-favicon"
import SattvaSignet from "../../../components/developers/sattvadeveloper/ourproperties/SattvaSignet";
import TagManager from 'react-gtm-module'

const favicon_logo = require("../../../images/favicon.png");

const SattvaSignetPage = () => {
  useEffect(() => {
    TagManager.initialize({ gtmId: 'GTM-J9C48LD' });
  }, []);

  return (
    <>
      <Favicon url={favicon_logo} />

      <SattvaSignet />
    </>
  )
}

export default SattvaSignetPage
